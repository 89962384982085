/** @format */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,900|Cabin:400,700|Droid+Serif:400,700|Playball");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --theme-color: #00205b;
  --secondary-color: #1a9aa5;
  --dark-color: #222;
  --white-color: #fff;
  --danger-color: #ff001f;
  --red-color: #e1045f;
  --yellow-color: #fbb904;
  --purple-color: #39a79e;
  --white-light: #f6fafd;
  --light-blue: #2474a73b;
  --light-red: #eaf8f5;
  --body-font: "Mulish", sans-serif;
  --vz-success: #0ab39c;
  --vz-gray-300: #e9ebec;
  --vz-blue: #3577f1;
  --vz-indigo: #405189;
  --vz-purple: #6559cc;
  --vz-pink: #f672a7;
  --vz-red: #f06548;
  --vz-orange: #f1963b;
  --vz-yellow: #f7b84b;
  --vz-green: #0ab39c;
  --vz-teal: #02a8b5;
  --vz-cyan: #299cdb;
  --vz-white: #fff;
  --vz-gray: #878a99;
  --vz-gray-dark: #343a40;
  --vz-gray-100: #f3f6f9;
  --vz-gray-200: #eff2f7;
  --vz-gray-300: #e9ebec;
  --vz-gray-400: #ced4da;
  --vz-gray-500: #adb5bd;
  --vz-gray-600: #878a99;
  --vz-gray-700: #495057;
  --vz-gray-800: #343a40;
  --vz-gray-900: #212529;
  --vz-primary: #405189;
  --vz-secondary: #3577f1;
  --vz-success: #0ab39c;
  --vz-info: #299cdb;
  --vz-warning: #f7b84b;
  --vz-danger: #f06548;
  --vz-light: #f3f6f9;
  --vz-dark: #212529;
}
.navbar {
  font-family: var(--body-font) !important;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font) !important;
  background: #f3f3f3;
  font-weight: normal;
  font-style: normal;
  color: var(--dark-color);
  overflow-x: hidden;
  font-size: var(--normal-font-size);
  line-height: 30px;
  font-size: 14px;
}
.possetting {
  font-family: var(--body-font) !important;
}
a {
  color: var(--theme-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

label {
  font-weight: 500;
}

.form-control {
  font-size: 12px;
}

p {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

.navbar.fixed-top + .page-body-wrapper {
  padding-top: calc(63px + 0px);
}

.btn {
  font-size: 14px;
}

.all_btn {
  background: var(--theme-color);
  border: none;
  font-size: 14px;
}

.all_btn:hover {
  background: var(--purple-color);
}

.order-top .card-body {
  padding: 0;
}

.order-card i {
  color: #fff;
  font-size: 21px;
  background: var(--theme-color);
  padding: 1em;
}

.order-card .card-1 {
  background: var(--secondary-color);
}

.order-card .card-2 {
  background: var(--yellow-color);
}

/* Inventory starts */

.items_tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: var(--theme-color);
  color: var(--white-color);
}

.items_tab .nav-pills .nav-link {
  border-radius: 0.25rem;
  background: var(--purple-light);
  color: #000;
  margin-right: 5px;
}

.my_category {
  position: relative;
  padding: 12px 30px;
  background-color: #fff;
  border-bottom: 1px solid #e1e6ef;
  margin-bottom: 20px;
}

.intentory_header_icon {
  font-size: 55px;
  color: var(--theme-color);
  width: 68px;
  float: left;
  margin-top: -4px;
  line-height: 0;
}

.my_category .header-title {
  margin-left: 68px;
}

.my_category .header-title h1 {
  margin: 0;
  font-size: 24px;
}

.my_category .header-title small {
  font-size: 13px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 600;
  color: #7a7a7a;
}

.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 109px;
  max-width: 122px;
  height: 30px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: var(--purple-color);
}

.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "Inactive";
  position: absolute;
  top: 0px;
  left: 35px;
  width: 100px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "Active";
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

.toggle-switchArea {
  margin: 10px 0 10px 0;
}

.offer a {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background: var(--yellow-color);
  padding: 5px;
  font-weight: 500;
  border-radius: 6px;
}

.light-purple {
  background: var(--purple-light);
  padding: 5px;
}

.addacc_form .form-control-file {
  outline: none;
}

.addacc_form .form-control-file::before {
  content: "Choose File";
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  padding: 5px 9px;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  background: var(--theme-color);
  color: #fff;
  position: absolute;
}

.select_form label {
  padding-right: 5px;
  top: 3px;
  position: relative;
}

.addindegridents a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: var(--purple-color);
  padding: 5px;
  font-weight: 500;
  border-radius: 6px;
}

.light-yellow {
  background: #fbb90447;
  padding: 5px;
}

.copy.hide {
  display: none;
}

.add-more {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  display: flex;
  background: var(--theme-color);
  color: #fff;
}

.add-more1 {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  display: flex;
  background: var(--red-color);
  color: #fff;
}

.remove_item .remove {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  color: #fff;
}

.remove1 {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  color: #fff;
}

/* Inventory part start */

.menu_left .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
}

.menu_left .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.menu_left .nav-sidebar .nav-link {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0.75rem 1.25rem;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
}

.menu_left .header-elements-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.menu_left .nav-sidebar {
  -ms-flex-direction: column;
  flex-direction: column;
}

.menu_left .bg-teal {
  background-color: #007bff;
  color: #fff;
  border-radius: 1px;
}

.menu_left .bg-teal:hover {
  color: #fff;
  background-color: #007bffb5;
}

.menu_left .sidebar-light .nav-sidebar .nav-link {
  color: rgba(51, 51, 51, 0.85);
}

.menu_left .badge {
  display: inline-block;
  padding: 0.3125rem 0.375rem;
  font-size: 75%;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.menu_left .nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0.12502rem;
  margin-bottom: 0.12502rem;
  top: 0;
}

.menu_left .nav-link {
  color: rgba(51, 51, 51, 0.85);
}

.menu_left .nav-link:not(.disabled):hover {
  color: #333;
  background-color: #f5f5f5;
}

.menu_left .nav-sidebar .nav-link {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0.75rem 1.25rem;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
}

.menu_left .nav-sidebar .nav-item-header {
  padding: 0.75rem 1.25rem;
  margin-top: 0.5rem;
}

.menu_left .nav-link {
  display: block;
  padding: 5px 1.25rem;
}

.nav-sidebar a {
  color: #000;
}

.nav-sidebar li {
  padding: 0.75rem 1.25rem;
  padding-bottom: 0;
}

.menu_left .nav-sidebar .nav-link.active {
  color: var(--theme-color);
}

/* Login and register */

.mycol img {
  object-fit: cover;
}

.mycol {
  padding: 0;
}

@media (max-width: 767px) {
  .mycol {
    margin-bottom: 2em;
  }
}

.w-80 {
  width: 47%;
}

.loginlogo {
  background: #fff;
  padding: 3px;
  border-radius: 100%;
  object-fit: contain;
  width: 34px;
  height: 34px;
}

/* order */

.table td,
.table th {
  padding: 5px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
}
.custom-table {
  max-height: 400px !important;
  overflow-y: scroll !important;
}
/* .sales-table th,
td {
  padding: 0px !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
} */

.menu_right .btn-sm {
  padding: 4px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-size: 11px !important;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  text-align: center;
}

.w-16 {
  width: 8em;
}

.bg-light-blue {
  background: var(--light-blue);
}
/* .bg-light {
  background-color: #f5f8fa !important;
} */
.form-group label i {
  background: var(--theme-color);
  color: #fff;
  padding: 2px;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  font-size: 9px;
  margin-left: 5px;
  line-height: 11px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  /* margin: auto; */
  align-items: center;
  /* display: flex; */
}

.control-group {
  display: flex;
}

.theme-btn {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  background: var(--theme-color);
  color: #fff;
}

.bg-light-blue h4 {
  border-bottom: 1px solid #2474a7;
  margin-bottom: 1em;
  padding-bottom: 10px;
}

.form-group label {
  font-weight: 600;
  font-size: 14px;
}

.order_transaction .form-check-input {
  border: 2px solid #b3b3b3;
  background-color: #fff;
  position: relative;
  display: inline-block;
  /* vertical-align: middle; */
  cursor: pointer;
  flex: 0 0 20px;
  top: 1px;
}

.order_transaction .dropdown-menu.dropdown-menu-right.navbar-dropdown {
  right: auto !important;
  top: auto !important;
}

.order_transaction .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
  display: none;
}

.order_transaction .dropdown-menu.dropdown-menu-right.navbar-dropdown {
  min-width: 160px;
  max-width: 320px;
  width: -webkit-max-content;
  width: max-content;
  padding: 10px;
}

/* vendor supplier form */

/* supplier card */

.supplier_card li {
  padding: 13px 10px 12px 10px;
  position: relative;
  word-wrap: break-word;
  cursor: pointer;
  border-bottom: 1px solid #e9edf2;
}

.supplier_card li:last-child {
  border-bottom: unset;
}

.supplier_card li:hover {
  background: #23abff47;
}

.supplier_card {
  position: absolute;
  overflow: hidden;
  zoom: 1;
  width: 209px;
  top: 75px;
  z-index: 19001;
  height: 143px;
  overflow-y: scroll;
}

#formButton {
  display: block;
}

.form2 {
  display: none;
}

.delete {
  color: red;
}

.wmin-md-400 {
  width: 400px;
}

.supplier_filter1 a i {
  background: transparent;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  margin: auto;
  line-height: 16px;
  text-align: center;
  padding: 0px;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

@media (max-width: 767px) {
  .wmin-md-400 {
    width: auto;
  }
}

/* Add notes */

/* Add Notes */

.notes_details .avatar {
  z-index: 2;
}

.circle {
  border-radius: 500px;
}

.mynotes {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
  padding: 0;
  margin: 0;
}

.notes_details {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.notes_details > * {
  padding: 10px;
}

.notes_details .avatar {
  z-index: 2;
}

.notes_details:last-child .notes_dot:after {
  display: none;
}

.notes_details.active .notes_dot:before {
  border-color: var(--theme-color);
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.notes_details:last-child .notes_dot:after {
  display: none;
}

.notes_details.active .notes_dot:before {
  border-color: var(--theme-color);
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.notes_dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.notes_dot:after,
.notes_dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.notes_dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

notes_details.active .notes_dot:before {
  border-color: var(--theme-color);
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.notes_dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.notes_dot:after,
.notes_dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.notes_dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

.notes_content p:last-child {
  margin-bottom: 0;
}

.notes_date {
  font-size: 0.85em;
  margin-top: 2px;
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
  border-color: var(--theme-color) !important;
}

.b-primary {
  border-color: var(--theme-color) !important;
}

.b-danger {
  border-color: var(--secondary-color) !important;
}

/* googleaut */

.google_aut .card {
  /* width: 400px; */
  padding: 20px;
  border: none;
}

.google_aut i {
  border: 1px solid;
  border-radius: 100%;
  padding: 7px;
  margin-right: 5px;
}

.register .card {
  max-width: 800px;
  margin: auto;
}

.google_aut h4 {
  font-size: 19px;
  font-weight: 600;
}

.med1 {
  padding: 30px 30px 15px;
}

span.text-muted {
  font-size: 12px;
}

.myprofile .a1:hover {
  background-color: var(--purple-light);
  color: #000;
  cursor: pointer;
}

.myprofile .a1:hover .fas {
  color: #000;
}

.media-body {
  line-height: 22px;
}

.innerpills .nav-pills .nav-link.active,
.innerpills .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--theme-color);
  font-weight: 600;
}

.google_aut .nav-pills .nav-link.active,
.google_aut .nav-pills .show > .nav-link {
  color: var(--theme-color);
  background-color: var(--theme-color);
  font-weight: 600;
}

.menutabs .nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 3px solid #dc3545;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.menutabs .nav-tabs .nav-link {
  background: 0 0;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  color: #2c2727;
}

/* menu */

.recentstores img {
  height: 108px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.p-15 {
  padding: 15px;
}

.border-gray {
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.recentstores a {
  color: #000;
}

.recentstores a:hover h6 {
  color: var(--theme-color);
}

.homecart {
  color: #fff !important;
  font-weight: 600;
  background: var(--secondary-color);
  padding: 3px 11px;
  border-radius: 500px;
}

.homecart:hover {
  color: var(--white-color) !important;
  background: var(--secondary-color);
}

.navbar.fixed-top + .page-body-wrapper1 {
  padding-top: calc(63px + 0px);
}

.addtocart {
  position: absolute !important;
  /* left: 0; */
  /* right: 0; */
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: #ff0017;
  color: #ffffff;
  font-size: 11px;
  top: 6px !important;
  font-weight: 600;
  /* line-height: 1rem; */
  border: none;
  margin-left: 16px;
}

.bg-theme {
  background: var(--theme-color);
}

/* cartdetails */

.quantity {
  width: 80px;
  height: 28px;
  /* border: 1px solid #eaeaea; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 700;
  border-radius: 30px;
  background: var(--secondary-color);
}

.quantity i {
  cursor: pointer;
  color: #fff;
}

.quantity span {
  margin-top: -2px;
  color: #fff;
}

.widget-cart-item .btn-close {
  position: absolute;
  top: 21px;
  left: -6px;
  height: auto;
  padding: 0;
  transform: translateY(-50%);
  transition: visibility 0.25s ease-in-out, opacity 0.25s ease-in-out;
  background: none;
  font-size: 1.5rem;
  font-weight: 300;
  visibility: hidden;
  opacity: 0;
}

.widget-cart-item:hover .btn-close {
  visibility: visible;
  opacity: 1;
}

.widget-cart-item {
  position: relative;
}

.widget-cart-item > .d-flex {
  transition: all 0.25s ease-in-out;
}

.widget-cart-item:hover > .d-flex {
  transform: translateX(1.125rem);
}

.textbox textarea {
  background: var(--white-light);
}

.widget-cart-item img {
  width: 67px;
  height: 42px !important;
  object-fit: contain;
}

.cartdetails {
  margin-top: 10px;
}

.details_inner {
  height: 26em;
  overflow-y: scroll;
  overflow-x: hidden;
}

.total-price {
  padding: 0px;
  font-weight: 600;
  font-size: 13px;
}

@media (min-width: 1600px) {
  .details_inner {
    height: 36em;
    overflow-y: scroll;
  }
}

.fs-md {
  font-size: 0.9375rem !important;
}

.product-title > a {
  transition: color 0.25s ease-in-out;
  color: #373f50;
  text-decoration: none !important;
}

.fs-ms {
  font-size: 0.8125rem !important;
}

.lh-20 {
  line-height: 20px;
}

.card-img-top {
  object-fit: cover;
  padding: 15px;
}

.filterform {
  line-height: 18px;
  font-size: 14px;
}

.t-2 input {
  position: relative;
  top: 2px !important;
}

.t-2 label {
  font-size: 12px;
  line-height: 15px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dee2e6 !important;
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
}

.form-check {
  position: relative;
  text-align: left;
}

.form-switch-lg .form-check-input {
  width: 48px;
  height: 24px;
  left: -0.75rem;
  position: relative;
}

.form-check-input:checked {
  background-color: #405189;
  border-color: #405189;
}

.form-select {
  font-size: 12px;
}

.form-group {
  margin-bottom: 1rem;
}

/* tabletab */

.project-tabs .nav-link {
  border: 1px solid transparent;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}

.project-tabs .nav-link:hover {
  border: none;
}

.project-tabs a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.project-tabs .nav-tabs .nav-link.active {
  color: #000;
  background: transparent !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.project-tabs .nav-tabs .nav-link.active {
  background: #2474a726 !important;
  border: none;
}

.project-tabs .nav-tabs .nav-item {
  margin-bottom: 5px;
  border-radius: 0;
  background: #fff;
  border: 0;
  margin-right: 2px;
  padding: 0.8rem 1rem;
}

.autoComplete_wrapper {
  display: block;
}

.autoComplete_wrapper {
  position: relative;
}

.autoComplete_wrapper > input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-image: none;
}

.autoComplete_wrapper > ul {
  border-radius: 0.25rem;
  border-color: #ced4da;
  -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  padding: 0;
  overflow: auto;
  max-height: 160px;
  margin: 0;
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.autoComplete_wrapper > ul .no_result {
  padding: 0.7rem 1.2rem;
  font-style: italic;
  font-weight: 500;
}

input:focus {
  color: #000;
  border: 0.06rem solid #ced4da;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  color: var(--theme-color);
  font-weight: 600;
}

.budgetlisttab .nav-tabs > .nav-item > .nav-link.active {
  background: #f3f3f3;
  color: var(--theme-color);
  font-weight: 600;
}

.budgetlisttab .nav-tabs > .nav-item > .nav-link {
  background: #fff;
  color: #000;
  font-weight: 600;
  border-radius: 0;
  margin-right: 1px;
  border-botton: 0;
  margin-bottom: 2px;
}

/* carousel */

.slick-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11%;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
}

.slick-dots li {
  list-style: none;
  margin: 15px 0;
}

.slick-dots button {
  height: 6px;
  border: none;
  background-color: #f00;
  text-indent: -9999px;
  outline: 0;
  background: #fc8b07;
  text-indent: -9999px;
  margin-right: 2px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 4em;
  border-radius: 7px;
  margin-top: 0;
  cursor: pointer;
  border: 1px solid #fc8b07;
}

.slick-dots button:hover {
  background-color: #000;
}

.myslider .media {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.slick-active button {
  background: red !important;
}

.bg-red-light {
  background: #ff00002b;
}

.text-sm {
  font-size: 0.875rem !important;
}

.media {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}

.media-body {
  flex: 1;
}

h5.mt-0.job-company {
  font-size: 14px;
  margin-bottom: 0;
}

#divMsg {
  display: none;
}

.suppliernewitem_form a i {
  background: transparent;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  margin: auto;
  line-height: 16px;
  text-align: center;
  padding: 0px;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

.bg-light-green {
  background: #dcf1ee;
}

.bg-color {
  background: #f2f2f2;
  padding: 14px;
  color: #000;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* register */

.regimg {
  height: 42em;
  overflow: hidden;
}

.regimg img {
  object-fit: cover;
}

.trialimg {
  height: 15em;
  overflow: hidden;
}

.trialimg img {
  object-fit: contain;
  height: 11em;
}

.welcome {
  max-width: 350px;
  padding: 30px 20px;
  border: solid 5px #fff;
  text-align: center;
}

.welcome h1 {
  font-size: 28px;
  color: #50c7bb;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.regpara {
  max-width: 350px;
  text-align: center;
  margin: auto;
}

.welcome h1 a {
  color: #fff;
}

.welcome p {
  color: #fff;
  line-height: 28px;
}

.poslist li:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  margin-right: 5px;
  color: #50c7bb;
}

.poslist li {
  text-align: start !important;
  color: #fff;
  margin-bottom: 10px;
}

ul {
  list-style: none;
}

.contactus .btn-block {
  border-radius: 0;
  border: none;
  background: blue;
  margin-top: 10px;
  /* padding-top: 0px; */
  background: #027676;
}

.h100 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-55 {
  font-size: 55px;
}

.int_logo {
  object-fit: contain;
}

.btn_red {
  background: var(--red-color) !important;
  color: #fff !important;
}

.text-theme {
  color: var(--theme-color);
}

.bg-light-red {
  background: var(--light-red);
}

.choices__input:focus {
  border: 0 !important;
}

.eodoffcanvas {
  background: #e9f3f3;
}

.bg-theme-secondary {
  background: var(--secondary-color);
}

/* eodfinal */

.eodfinal .sum-up {
  text-align: right !important;
}

.eodfinal .total {
  font-size: 13px;
  border-top: 1px dashed #fff !important;
  border-bottom: 1px dashed #fff !important;
}

.eodfinal p {
  padding: 1px;
  margin: 0;
}

.eodfinal section,
footer {
  font-size: 12px;
  color: #fff;
}

.text-center.topeod p {
  line-height: 17px;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  /*width: 450px;*/
  max-width: 100%;
  padding: 15px;
  border: 1px dashed;
  border-radius: 3px;
  transition: 0.2s;
  font-size: 12px;
}

.choose-file-button {
  flex-shrink: 0;
  background-color: var(--light-red);
  border: 1px solid var(--light-red);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  widows: 100%;
  cursor: pointer;
  opacity: 0;
}

.postop ::placeholder {
  color: #000;
}

.postop input.form-control {
  /* background: #fbb904 !important; */
  color: #000;
}

.btn-group.btn1 .active {
  background: var(--theme-color);
  border: 0;
}

.bg-green {
  background: var(--secondary-color);
  border: 0;
}
.bg-pink {
  background: #e1045f;
  border: 0;
}

.btn-group {
  border: 0;
}

.btn1 a {
  margin-right: 2px;
  border-radius: 0;
}

.btn1 .btn-primary:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.btn-primary:active:focus {
  box-shadow: unset;
}

.btn-primary:active {
  background: var(--theme-color);
}

.paybtn a {
  width: 85px;
}

.form-control-file::before {
  content: "Choose File";
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  padding: 5px 10px;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  background: var(--theme-color);
  color: #fff;
  position: absolute;
}

/* dashboard css */

.fs-16 {
  font-size: 16px !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.btn-success {
  color: #fff !important;
  background-color: #0ab39c !important;
  border-color: #0ab39c !important;
}

.statstop .card-animate {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.statstop .card {
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  box-shadow: 0 1px 2pxrgba (56, 65, 74, 0.15);
  border: 0;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600 !important;
}

.ff-secondary {
  font-family: hkgrotesk, sans-serif;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.bg-soft-info {
  background-color: rgba(41, 156, 219, 0.18) !important;
}

.bg-soft-info {
  background-color: rgba(41, 156, 219, 0.18) !important;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #405189;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-soft-light {
  background-color: rgba(243, 246, 249, 0.18) !important;
}

.border-dashed {
  border-style: dashed !important;
}

.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}

.height-100 {
  height: calc(100% - 1.5rem);
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}

.btn-soft-secondary {
  color: #3577f1;
  background-color: rgba(53, 119, 241, 0.1);
  border-color: transparent;
  font-size: 0.7109375rem;
}

.btn-soft-primary {
  color: #405189;
  background-color: rgba(64, 81, 137, 0.1);
  border-color: transparent;
  font-size: 0.7109375rem;
}

.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px;
}

.dropdown-item {
  font-size: 13px;
}

.dashboardcard .dropdown-menu {
  inset: 0px 0px auto auto !important;
}

.dashboardcard .dropdown-menu {
  top: 50px !important;
  left: auto !important;
  overflow: hidden;
  background: #fff;
  z-index: 3;
  -webkit-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
  margin: 0;
  border: 0;
  -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  border-radius: 0;
}

.dashboardcard .dropdown-menu.show {
  top: 30px !important;
  opacity: 1;
  visibility: visible;
}

.dashboardcard .dropdown-menu.show {
  display: block;
}

.fs-16 {
  font-size: 16px;
}

#cardlist,
#cardlist1,
#expiredcardlist {
  display: none;
}

.marketingupload {
  width: 100%;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  background: #f8f8f9;
  color: #666;
  overflow: hidden;
}

.purchase_wrapper form img {
  margin-bottom: 20px;
  width: auto;
  height: auto;
  max-height: 400px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
}

.marketingupload img {
  height: 100% !important;
  width: auto !important;
  border-radius: 0px;
  margin: 0 auto;
}

.marketingupload i {
  font-size: 6em;
  color: #ccc;
}

.marketingupload input {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.purchase_wrapper input {
  height: 43px;
  line-height: 43px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.iti {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* google 2fa */

/* vertical tab */

.theme-color {
  background: var(--theme-color);
}

.google_aut .innerpills .nav-pills .nav-link.active,
.google_aut .innerpills .nav-pills .show > .nav-link {
  color: #fff;
  border-radius: 0;
}

.google_aut .nav-link {
  color: #fff;
  background: var(--secondary-color);
  border-radius: 0;
  /* margin-right: 1px; */
  /* width: auto; */
  border: 1px solid #fff;
  padding: 0.5rem 0.5rem !important;
}

@media (min-width: 1800px) {
  .google_aut .nav-link {
    padding: 0.5rem 2rem !important;
  }
}

.w-30 {
  width: 30%;
}

.pospaymentcanvas {
  top: 0;
  right: 0;
  width: 500px !important;
}

.pos_sender_details .card {
  background: #e9f3f3;
}

.recentstores {
  height: 50em;
  overflow-y: auto;
  overflow-x: hidden;
}

/* subscription */

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--purple-color);
  border-color: var(--purple-color);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.bg-primary1 {
  background: var(--theme-color);
  color: #fff;
}

.bg-theme1 {
  background: var(--secondary-color);
}

/* pricing */

.menu_inner.subscribe_plan_modal li {
  font-size: 12px;
}

.menu_inner.subscribe_plan_modal .price-label {
  font-size: 14px;
}

.h-38 {
  height: 29em;
  overflow-y: scroll;
}

@media (min-width: 1800px) {
  .h-38 {
    height: 38em;
    overflow-y: scroll;
  }
}

.price-label.basic {
  background: #e8eaf6;
  color: #3f51b5;
}

.price-label.value {
  background: #e8f5e9;
  color: #4caf50;
}

.price-label.premium {
  background: #fbe9e7;
  color: #ff5722;
}

.price-box ul li .fas {
  color: #68ae4a;
  margin-right: 7px;
  font-size: 12px;
}

.price-box ul {
  padding: 10px 0px 30px;
  margin: 17px 0 0 0;
  list-style: none;
  border-top: solid 1px #e9e9e9;
}

.price-box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.price-box .info ul {
  height: 41em;
  overflow-y: scroll;
}

.newcard_details .price-box {
  height: unset;
  overflow: hidden;
}

.price-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.34;
  margin-bottom: 0;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 3px;
}

.price-label.basic {
  background: #e8eaf6;
  color: #3f51b5;
}

.price {
  font-size: 44px;
  line-height: 44px;
  margin: 15px 0 6px;
  font-weight: 900;
}

.price-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.67;
  color: inherit;
  width: 100%;
  margin: 0;
  color: #989898;
}

.plan-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 5px 26px;
  border: 0px solid #b3b3b3;
  color: #fff !important;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 10px 0;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  background: var(--secondary-color);
}

.plan-btn::after {
  position: absolute;
  left: -100%;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--theme-color);
  z-index: -1;
  transition: all 0.35s ease-in-out;
}

.plan-btn:hover::after {
  left: 0;
}

.plan-btn:hover,
.plan-btn:focus {
  text-decoration: none;
  color: #fff;
  border: 0px solid var(--theme-color);
}

@media (max-width: 991px) {
  .price-box {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .main-heading {
    font-size: 21px;
  }
  .price-box {
    margin-bottom: 20px;
  }
}

/* change organization */

.my_company {
  background: var(--secondary-color);
  padding: 9px !important;
  border-radius: 3px;
}

.my_company::after {
  color: #fff !important;
}

.ellipsis1 {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 13px;
  padding-left: 9px;
}

.theme-color1 {
  color: var(--theme-color);
}

.item-thumbnail img {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  object-fit: contain;
  background: #f7f4f4;
  padding: 3px;
}

.receiptpos .table td,
.receiptpos .table th {
  padding: 0;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
  border: 0;
  color: #fff;
}

.orderfilter .table td,
.orderfilter .table th {
  padding: 0;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
  border: 0;
  font-weight: 600;
}

.bordercolor {
  border-top: 1px solid var(--theme-color);
}

.w-20 {
  width: 16%;
  padding: 0px;
  text-align: center;
}

.orderfilter .card {
  margin-bottom: 15px;
}

.orderimg {
  width: 58px;
  border-radius: 100%;
  height: 58px;
  object-fit: cover;
}

.bg-color1 {
  background: var(--light-red);
}

.posorderpaymentcanvas {
  top: 0;
  right: 0;
  width: 65em !important;
}

.navbar-menu-wrapper .btn-primary {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.navbar-menu-wrapper .btn-primary:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.innersec {
  padding-bottom: 10px;
  padding-top: 10px;
}

.selectimagecanvas {
  z-index: 99999;
  height: 45vh;
}

.modaladdimage img {
  width: 60px;
  border: 1px solid #eee;
}

.giftupload {
  display: inline-block;
  border: 10px solid #fff;
  box-shadow: 0 1px 15px 1px rgb(0 0 0 / 10%);
  /* padding: 5px; */
}

.giftimages {
  overflow-y: scroll;
  height: 36em;
}

.giftimages img {
  margin-bottom: 30px;
  box-shadow: 0 1px 15px 1px rgb(0 0 0 / 10%);
}

.lg-backdrop {
  z-index: 1050;
}

.newsearch i {
  padding: 6px;
  color: #fff;
  background: var(--theme-color);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
  margin-right: 9px;
}

.newadd {
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  display: flex;
  background: var(--theme-color);
  color: #fff;
}

.cf1 {
  background-color: var(--red-color);
  border: 1px solid var(--red-color);
  color: #fff;
}

.text1 {
  font-size: 20px;
  font-weight: 600;
}

.recentstores1 {
  height: unset;
  overflow: hidden;
}

.btn-check:focus + .btn-primary,
.btn:focus {
  color: #fff;
  background-color: #0099a4 !important;
  border-color: #0099a4 !important;
  box-shadow: unset;
}

.btn:active:focus {
  box-shadow: unset;
}

.inv_img img {
  height: 100px;
  object-fit: cover;
}

.tablecard_img img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border: 1px solid #eee;
  border-radius: 100%;
  padding: 2px;
  margin-right: 5px;
}

.height-30 {
  height: 20em;
}

.file-drop-area1 {
  position: relative;
  display: flex;
  align-items: center;
  /* width: 450px; */
  max-width: 100%;
  padding: 39px 3px;
  border: 1px dashed;
  border-radius: 3px;
  transition: 0.2s;
  font-size: 12px;
}

.set_img img {
  height: 135px;
}

.newheight {
  height: auto;
  overflow: unset;
}

.table_variance td,
.table_variance th {
  padding: 5px;
  vertical-align: top;
  border-top: 0;
  font-size: 12px;
}

.printimg img {
  width: 50px;
}

.bg-secondary_light {
  background: #d4ecee;
}

.bg-secondary1 {
  background: var(--secondary-color);
}

.lucapaylogo p {
  line-height: 24px;
  font-size: 15px;
}

.innertext p {
  line-height: 17px;
  font-size: 13px;
}

.lh {
  line-height: 13px;
  font-size: 10px;
}

.bg-theme-lucapay {
  background: #364470;
}

.no_product {
  width: 16%;
  margin-left: auto;
  display: flex;
}

/* eod */

.count1 h3 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
}

.count1 span {
  font-size: 23px;
  line-height: 35px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #fff;
  margin-top: 6px;
}

.count1 i {
  font-size: 40px;
  color: #fff;
}

.count1 {
  padding: 0 4px;
  background: var(--theme-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 0px;
}

.w-15 {
  width: 15%;
  margin: auto;
}

.height100 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewleavemodal .icon img {
  width: 58px;
  height: 58px;
  border-radius: 15px;
}

.viewleavemodal .icon {
  width: 60px;
  height: 60px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
}

.c-details span {
  font-weight: 300;
  font-size: 13px;
}

.reason p {
  line-height: 18px;
}

.leaveheight {
  max-height: 25.5em;
  overflow-y: auto;
}

.selectpos img {
  width: 114px;
  height: 81px;
  object-fit: contain;
}

.c1 {
  background: #ffe2e5;
  border-radius: 10px;
}

.c2 {
  background: #fff4de;
  border-radius: 10px;
}

.c3 {
  background: #dcfce7;
  border-radius: 10px;
}

.c4 {
  background: #f3e8ff;
  border-radius: 10px;
}

.card_height {
  overflow-y: auto;
  height: 24em;
}

.template-setting .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 15px 15px !important;
  font-size: 14px;
  background: transparent;
  outline: none;
  cursor: pointer;
}
.template-setting .ant-tabs-tab {
  margin: 0 10px 0 8px !important;
}
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-tile-group .input-container {
  position: relative;
  height: 7rem;
  width: 7rem;
  margin: 0.5rem;
}

.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
}

.radio-tile-group .input-container .icon svg {
  fill: var(--secondary-color);
  width: 3rem;
  height: 3rem;
}

.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--secondary-color);
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
}
.active-payment-method {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: white;
  transform: scale(1.1, 1.1);
  transition: transform 300ms ease;
}
.active-payment-method .radio-tile-label {
  color: white !important;
  background-color: var(--secondary-color);
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .icon
  svg {
  fill: white;
  background-color: var(--secondary-color);
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
}
.radio-tile-group .ant-radio-inner {
  display: none !important;
}
.filter-modal .modal-content {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  border: none !important;
}
.no-backdrop .modal-backdrop {
  display: none !important;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: absolute;
  top: 0;
  left: 0;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.newsupplier_form .ck.ck-editor {
  position: relative;
  width: 70vw !important;
}
.variation-select {
  width: 20vw;
}
.price-modifier .ant-popover {
  min-width: 30vw !important;
}
.sidebar::-webkit-scrollbar {
  width: 4px !important;
}
.details_inner::-webkit-scrollbar {
  width: 3px !important;
}

.sidebar {
  /* min-height: calc(100vh - 63px); */
  height: 100vh !important;
  position: fixed;
  background: var(--white-color);
  padding: 0;
  width: 236px !important;
  overflow-y: scroll !important;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}
.main-panel {
  margin-left: 236px !important;
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 270px);
  min-height: calc(100vh - 63px);
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 756px) {
  .main-panel {
    width: 100%;
    margin-left: 0px !important;
  }
}

.react-loading-skeleton {
  animation: pulse 0.75s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
.react-progress-bar-spinner-icon {
  display: none !important;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: react-progress-spinner 400ms linear infinite;
}
.react-progress-bar-percent {
  height: 2.5px;
  background: #ff0100 !important;
  box-shadow: 0 0 10px #29d, 0 0 5px #ff0100 !important;
  transition: all 200ms ease;
}
.add-organisation {
  height: 100% !important;
  width: 100% !important;
  padding-top: 3rem;
  background-image: radial-gradient(circle at 0 0, #ffffff00 0, #5fd5b600 30%),
    linear-gradient(180deg, #79dfffb3 -10%, #79dfff00 70%),
    radial-gradient(circle at 95% 0, #d0f224 0, #5fd5b600 20%) !important;
}
.loginPage {
  background-image: radial-gradient(circle at 0 0, #ffffff00 0, #5fd5b600 30%),
    linear-gradient(180deg, #79dfffb3 -10%, #79dfff00 70%),
    radial-gradient(circle at 95% 0, #d0f224 0, #5fd5b600 20%) !important;
}
.print-text {
  color: #1b9aa5;
  font-style: normal;
  cursor: pointer;
  font-weight: bold;
}
.cont {
  display: flex;
  flex-direction: column;
}
.cont a {
  color: #00205b !important;
  margin-bottom: 5px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: unset !important;
  text-decoration: none;
  background-color: #fff !important;
}
.myinfo i {
  width: 40px;
  height: 40px;
  background: var(--theme-color);
  border-radius: 100%;
  text-align: center;
  /* margin: auto; */
  font-size: 18px;
  line-height: 37px;
  margin-right: 17px;
  color: #fff;
}
.pay-button {
  color: white !important;
  background-color: rgba(98, 177, 76, 1) !important;
}
.card {
  border: none !important;
}
.custom-modal .ant-modal-footer {
  background-color: #ff0000; /* Set your desired background color */
}
.fixed-top {
  z-index: 1003 !important;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 2000000015 !important;
  width: 100%;
  pointer-events: none;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}
